import * as React from "react"
import {
  getSrc,
} from "gatsby-plugin-image"

export default function ServiceDisability({ blocks }) {

  const header = blocks[0]
  const columns = blocks[1].relationships
  const { field_column_one, field_column_two } = columns

  const column1 = field_column_one.map(card => {
    return {
      name: card.field_card_title,
      description: card.field_card_body.processed,
      icon: getSrc(card.relationships.field_card_icon.relationships.field_media_image.localFile),
    }
  })

  const column2 = field_column_two.map(card => {
    return {
      name: card.field_card_title,
      description: card.field_card_body.processed,
      icon: getSrc(card.relationships.field_card_icon.relationships.field_media_image.localFile),
    }
  })

  const features = [...column1, ...column2]
  
  return (
    <div className="bg-white pb-20 pb-16 pt-32 md:pt-40 lg:pt-48 lg:pb-24">
      <div className="relative divide-y-2 divide-gray-200 mx-auto max-w-xl px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div>
          <div className="text-lg font-semibold text-sky-700">{header.field_header_tagline}</div>
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-sky-900 sm:text-4xl">{header.field_header_title}</h2>
          <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-1 lg:items-center lg:gap-5 text-xl text-gray-500"
               dangerouslySetInnerHTML={{ __html: header.field_header_subtitle.processed }} />
        </div>
      <dl className="mt-12 pt-12 sm:pt-16 lg:20 space-y-10 sm:grid sm:grid-cols-1 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-2 lg:gap-x-8">
        {features.map((feature) => (
          <div key={feature.name} className="relative">
            <dt>
              <img className="h-10 w-10" alt="Checkmark" src={feature.icon} aria-hidden="true" />
              <p className="text-xl mt-4 font-semibold leading-6 text-sky-900">{ feature.name }</p>
            </dt>
            <dd className="mt-2 text-base text-gray-500"
                dangerouslySetInnerHTML={{ __html: feature.description }} />
          </div>
        ))}
      </dl>
    </div>
  </div>
  )
}