import * as React from "react"

export default function Header({ header }) {
	return (
		<div className="relative max-w-lg lg:max-w-7xl">
      <div>
        <h1 className="text-lg font-semibold text-sky-700">{header.field_header_tagline}</h1>
        <h2 className="mt-2 text-3xl font-bold tracking-tight text-sky-900 sm:text-4xl">{header.field_header_title}</h2>
        <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-1 lg:items-center lg:gap-5 text-xl text-gray-500"
             dangerouslySetInnerHTML={{ __html: header.field_header_subtitle.processed }} />
      </div>
    </div>	
	)
}