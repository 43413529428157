import * as React from "react"
import IconCheck from "../../images/icon-tick.png"
import Media from "../media"

export default function TwoColumnOddRowBlock({ row }) {
  // How We Work With You.
  if (row.text && row.column2Text) {
    return (
      <div className="lg:grid lg:grid-cols-2 lg:gap-8 mt-12 sm:mt-16 lg:mt-20">
        <div className="relative text-lg text-gray-500">
          <div className="textarea" dangerouslySetInnerHTML={{ __html: row.text }} />
          <ul className="relative mt-8 list-none ml-0">
            {row.listItems.map((feature) => (
              <li key={feature.name} className="mb-4 list-none">
                <img className="h-8 w-8 sg:h-10 sg:w-10 absolute" alt="Checkmark" src={IconCheck} aria-hidden="true" />
                <p className="ml-12 sg:ml-14 text-lg font-semibold leading-8 sg:leading-10 text-sky-900">{feature.name}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="relative mt-10 lg:mt-0" aria-hidden="true">
          <div className="mt-12 lg:mt-4 lg:ml-12 text-lg text-gray-500"
              dangerouslySetInnerHTML={{ __html: row.column2Text }} />  
        </div>
      </div>
    )
  }
	return (
    <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8 my-16 lg:my-24">
      {row.text && <div className="relative lg:col-start-2 text-lg text-gray-500">
        <div className="textarea" dangerouslySetInnerHTML={{ __html: row.text }} /> 
      </div>}    
      <div className="relative -mx-4 sm:mx-0 lg:ml-0 lg:-mr-2 mt-10 lg:col-start-1 lg:mt-0">  
        <div className="mx-auto sm:mx-0 lg:pr-12">
          <Media row={row} />
        </div>
      </div>
    </div>
	)
}