import * as React from "react"
import Media from "../media"

export default function TwoColumnEvenRowBlock({ row }) {
	return (
    <div className="lg:grid lg:grid-cols-2 lg:items-center lg:gap-8 mt-12 sm:mt-16 lg:my-20">
      <div className="relative text-lg text-gray-500"
           dangerouslySetInnerHTML={{ __html: row.text }} />
      <div className="relative -mx-4 sm:mx-0 lg:mr-0 lg:-ml-2 mt-10 lg:mt-0" aria-hidden="true">
        <div className="mx-auto sm:mx-0 lg:pl-12">
          <Media row={row} />
        </div>
      </div>  
    </div>
	)
}