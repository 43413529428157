import * as React from "react"
import TwoColumnEvenRowBlock from "./two-column-even-row-block"
import TwoColumnOddRowBlock from "./two-column-odd-row-block"

export default function TwoColumnBlock({ twoColumns, mediaPosition=`left` }) {
	return (
    <div className="mt-12">
      {twoColumns.map((row, index) => {
        let newIndex = index + 1;
        if (mediaPosition === `right`) {
            newIndex = index + 2
        }
        if (newIndex % 2 === 0) {
          newIndex++
          return <TwoColumnEvenRowBlock row={row} />
        }
        newIndex++ 
        return <TwoColumnOddRowBlock row={row} />
      })}
    </div>
	)
}