import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import ServiceDisability from "../components/service-disability"
import { buildTwoColumns } from "../utils/helpers"
import TwoColumnBlock from "../components/paragraphs/two-column-block"
import Header from "../components/paragraphs/header"

export default function OurServices({ data }) {
  const sections = data.sections.relationships.field_sections
  const disabilitySection = sections[0]
  const disabilityBlocks = disabilitySection.relationships.field_section_blocks

  const otherSection = sections[1]
  const otherBlocks = otherSection.relationships.field_section_blocks
  const otherHeader = otherBlocks[0]

  const otherTwoColumnSection = [ otherBlocks[1] ]
  const twoColumns = buildTwoColumns(otherTwoColumnSection)
  
  return (
    <Layout>
      <ServiceDisability blocks={disabilityBlocks} />
      <div className="overflow-hidden bg-gray-50 py-16 lg:pt-24 border-b-4 border-white">
        <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="divide-y-2 divide-gray-200">
            <Header header={otherHeader} />
            <TwoColumnBlock twoColumns={twoColumns} mediaPosition="right" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ location, data }) => {
  const canonicalUrl = `${process.env.GATSBY_SITE_URL}our-services/`
  const metaDescription = data.sections?.field_metatags?.description ?? ``
  return (
    <>
      <meta name="description" content={metaDescription} />
      <title>Our services | DEC Housing</title>
      <link rel="canonical" href={canonicalUrl} />
    </>
  )
}

export const query = graphql`
  query OurServicesPageQuery {
    sections: nodePage(drupal_internal__nid: {eq: 5}) {
      field_metatags {
        description
      }
      relationships {
        field_sections {
          ... on paragraph__section {
            relationships {
              field_section_blocks {
                ...HeaderFields
                ...TwoColumnBlockFields
              }
            }
          }
        }
      }
      title
    }  
  }
`